import React from "react";
import "./Header.css";
//import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

export default function Header() {
  const history = useHistory();
  return (
    <div className="header">
      <Button
            color="white"
            onClick={() => history.push("/")}
          >
            {" "}
            Pippetts
          </Button>
      <div className="header__right">
        <p> Services</p>
        <p> Contact </p>
        <p> About us </p>
        <Button
          variant="contained"
          color="black"
          onClick={() => history.push("/login")}
        >
          {" "}
          Login
        </Button>
      </div>
    </div>
  );
}
