import React from "react";
import "./ServicesPage.css";
import Card from "./Card";

function ServicesPage() {
  return (
    <div className="servicepage">
      <h2> Services</h2>

      <div className="service_cards">
        <Card
          src="/manual.png"
          title="Manual Trade Recovery"
          descrption=" Use our manual recovery service to obtain level of trade
                level recovery
                for the several layers of protection required to prevent a net loss"
          price="$49.99/Month"
        />
        <Card
          src="/auto.png"
          title="Auto Trade Recovery"
          descrption=" Use our Auto service to automatically recovery loss 
                utilizing our API which utilizes
                several layers of protection required to prevent a net loss"
          price="$499.99/Month"
        />
        <Card
          src="/signal.png"
          title="Trade Signals"
          descrption=" Get trades signals  for hot entry with a high win-rate
                The signals are determined utilizing  trade scanners that produce results 
                averaging similar trade set up"
          price="$99.99/Month"
        />
      </div>

      <div className="service_cards">
        <Card
          src="/alert.png"
          title="Alerts"
          descrption=" Get unlimited acess to trade alert for your favorite currency pairs. Never miss a trade"
          price="$19.99/Month"
        />
        <Card
          src="/mentor.png"
          title="Become a Mentor"
          descrption="Are you a  well versed and profitable trader ? Get paid for your skills by mentoring the
                next generation of traders
                "
          price="$4.99/Month"
        />
        <Card
          src="/Mentee.png"
          title="Mentee"
          descrption="  Are you interested in  learning how to trade and becoming a highly skilled trader ?.
                Our free mentee program provides you with the resoruces you need to excell"
          price="Free"
        />
      </div>
    </div>
  );
}

export default ServicesPage;
