import React from "react";
import "./App.css";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import ContactPage from "./ContactPage";
import ServicesPage from "./ServicesPage";
import LoginPage from "./LoginPage";
import SignUpPage from "./SignUpPage";
import ManualTrade from "./ManualTrade";


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//wrap app in Router

function App() {
  return (
    //BEM

    <div className="App">
      <Router>
        <Header />

        <Switch>
          <Route path="/services">
            <ServicesPage />
          </Route>

          <Route path="/login">
            <LoginPage />
          </Route>

          <Route path="/signup">
            <SignUpPage />
          </Route>

          <Route path="/contact">
            <ContactPage />
          </Route>

          <Route path="/manualTrade">
            <ManualTrade/>
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
