import React from 'react'
import "./MiniCards.css"
import { Button } from "@material-ui/core";



function MiniCards() {
    return (
        <div className="minicards">
            <div className="minicard">
                <h3>Manual Service</h3>
                <img src="/manual.png" alt="" />
                <div>
                    <p>Price $49.99</p>
                    <Button
                        variant="contained"
                        color="black"
                    >
                        {" "}
                        Access
                    </Button>

                </div>


            </div>
            <div className="minicard">
                <h3>Auto</h3>
                <img src="/auto.png" alt="" />
                <div>
                    <p>Price $49.99</p>
                    <Button
                        variant="contained"
                        color="black"
                    >
                        {" "}
                        Access
                    </Button>

                </div>


            </div>
            <div className="minicard">
                <h3>Signal</h3>
                <img src="/signal.png" alt="" />
                <div>
                    <p>Price $49.99</p>
                    <Button
                        variant="contained"
                        color="black"
                    >
                        {" "}
                        Access
                    </Button>

                </div>


            </div>

            <div className="minicard">
                <h3>Alert Service </h3>
                <img src="/alert.png" alt="" />
                <div>
                    <p>Price $49.99</p>
                    <Button
                        variant="contained"
                        color="black"
                    >
                        {" "}
                        Access
                    </Button>

                </div>


            </div>
            <div className="minicard">
                <h3>Mentor</h3>
                <img src="/mentor.png" alt="" />
                <div>
                    <p>Price $49.99</p>
                    <Button
                        variant="contained"
                        color="black"
                    >
                        {" "}
                        Access
                    </Button>

                </div>


            </div>
            <div className="minicard">
                <h3>Mentee</h3>
                <img src="/mentee.png" alt="" />
                <div>
                    <p>Price $49.99</p>
                    <Button
                        variant="contained"
                        color="black"
                    >
                        {" "}
                        Access
                    </Button>

                </div>

            </div>

        </div>
            )
}
            export default MiniCards
