import React from "react";
import "./ContactPage.css";

function ContactPage() {
  return (
    <div className="contactpage">
      <p>Contact Page</p>
    </div>
  );
}

export default ContactPage;
