import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

import "./Card.css";

function Card({ src, title, descrption, price }) {
  const history = useHistory();

  return (
    <div className="card">
      <img src={src} alt="" />

      <div className="card__info">
        <h2>{title}</h2>
        <h4>{descrption}</h4>
        <div className="card_price_get_acess">
          <h3>{price}</h3>
          <Button
            variant="contained"
            color="black"
            onClick={() => history.push("/manualTrade")}
          >
            {" "}
            Get
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Card;
