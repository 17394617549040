import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <p> © Pippetts. All rights Reserved </p>
      <p> Privacy . Terms . Sitemap. Company Detials </p>
    </div>
  );
}

export default Footer;
