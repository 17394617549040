import React from "react";
import "./Banner.css";

function Banner() {
  return (
    <div className="Banner">
      <img className="banner__image" src="/BannerImg.jpeg" alt="" />
      <div className="banner_text">
        {/*<p>
          Welcome to Pippetts. No bad days !!! We focus on making sure that
          people in the trading communtiy expereince a smooth and steady journey
          by providign services that limits losses. We offer several services
          such as manual loss recovery, auto loss recovery, trading alerts,
          trading signals, trading wiki, a trading social network, trading
          mentors and trading mentee.
        </p>*/}
      </div>
    </div>
  );
}
export default Banner;
