import React from 'react'
import ReactDOM from 'react-dom';
import "./TradeAssistance.css";



class TradeAssistance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountBalance: '',
            risk: '',
            maxLoss: '',
            madePips: 0,
            LossPips: 0.0,
            lotSize: 0.0,
            profit: 0.0,
            riskToReward: 0.0

        };
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }


    render() {
        return (

            <div className = "tradeAssitance">
                <form>
                    <h1> Ask Nelo </h1>
                    <br />

                    <p> Our trading virtual assitance helps in  determing trade outcomes</p>
                    <br />

                    <label>
                        Account Balance: 
                        <input
                            type='text'
                            name='accountBalance'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />
                 
                    <label>
                        Risk %: 
                        <input
                            type='text'
                            name='risk'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />

                    <label >
                        Max loss Per Trade: 
                        <input
                            type='text'
                            name='maxLoss'
                            onChange={this.myChangeHandler} />
                    </label >
                    <br />
                    <br />

                    <label>
                        Pips to be made: 
                        <input
                            type='text'
                            name='madePips'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />

                    <label>
                        Pips to be loss:
                        <input
                            type='text'
                            name='LossPips'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />

                    <label>
                        Lot size:
                        <input
                            type='text'
                            name='lotSize'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />

                    < label>
                        Profit:
                            <input
                            type='text'
                            name='profit'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />

                    <label>
                        Loss:
                        <input
                            type='text'
                            name='loss'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />
                    <label>
                        Risk to  reward :
                        <input
                            type='text'
                            name='riskToReward'
                            onChange={this.myChangeHandler} />
                    </label>
                    <br />
                    <br />



                </form >
            </div >
        );
    }
}


export default TradeAssistance
ReactDOM.render(<TradeAssistance />, document.getElementById('root'));




