
import React from 'react';
import ReactDOM from 'react-dom';
import Button from "@material-ui/core/Button";
import MiniCards from './MiniCards';



class ManualTrade extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pipsToBeMade: '',
            pipsToBeLost: '',
            accountValue: '',
            suggestedLot: 0.0,
            riskPercentage: 0,
            lotSize: 0.0,
            entryPrice: 0.0,

        };
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }



    render() {
        return (

            <div>
                
                <MiniCards/>
             
                <form>
                    <h1>Manual Recovery App {this.state.pipsToBeMade} {this.state.pipsToBeLost}</h1>
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Buy
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                    >
                        Sell
                    </Button>
                    <p> Enter pips to be made:</p>
                    <input
                        type='text'
                        name='pipsToBeMade'
                        onChange={this.myChangeHandler}

                    />
                    <p> Enter pips to be lost:</p>
                    <input
                        type='text'
                        name='pipsToBeLost'
                        onChange={this.myChangeHandler}
                    />
                    <p> Account Value:</p>
                    <input
                        type='text'
                        name='accountValue'
                        onChange={this.myChangeHandler}
                    />
                    <p> Risk Percentage:</p>
                    <input
                        type='text'
                        name='riskPercentage'
                        onChange={this.myChangeHandler}
                    />
                    <p> Suggested Lot Size:</p>
                    <input
                        type='text'
                        name='suggestedLot'
                        onChange={this.myChangeHandler}
                    />
                    <p> Enter lot size:</p>
                    <input
                        type='text'
                        name='lotSize'
                        onChange={this.myChangeHandler}
                    />
                    <p> Entry Price:</p>
                    <input
                        type='text'
                        name='entryPrice'
                        onChange={this.myChangeHandler}
                    />
                    <p> Layer 1 protection:</p>
                    <p> -----------------:</p>
                    <p> Take profit at:</p>
                    <p> Stop loss:</p>
                    <p> --------------------:</p>
                    <p> Layer 2 protection:</p>
                    <p> ---------------------:</p>
                    <p> Take profit at:</p>
                    <p> Stop loss:</p>
                    <p> -----------------:</p>
                    <p> Layer 3 protection:</p>
                    <p> -----------------:</p>
                    <p> Take profit at:</p>
                    <p> Stop loss:</p>
                    <p> --------------------:</p>
                    <p> Layer 4 protection:</p>
                    <p> ---------------------:</p>
                    <p> Take profit at:</p>
                    <p> Stop loss:</p>
                    <p> -----------------:</p>
                </form>
            </div>
        );
    }
}


export default ManualTrade
ReactDOM.render(<ManualTrade />, document.getElementById('root'));


